import React, { useContext, useEffect, useState } from 'react';
import Image from 'next/image';
import styled, { ThemeContext } from 'styled-components';
import { utils } from '@makeship/core';
import posthog from 'posthog-js';
import { H4 } from '../../../Typography';
import { useStore } from '../../../../store';
import { useWindowSize } from '../../../../hooks/useWindowSize';
import { parseCollectionMetafields } from '../../../../utils/collections';
import PreviewWarning from '../../../Preview/PreviewWarning';

const CreatorHeaderWrapper = styled.div`
  position: relative;
`;

const CreatorHeaderBackgroundWrapper = styled.div<{ isMobile: boolean }>`
  height: 218px;
  width: 100%;
  position: relative;
  padding-top: 36px;
  background: ${({ theme }) => theme.colors.makeshipBlack};
`;

const CreatorHeaderBackground = styled(Image)``;

const MaxDesktopWrapper = styled.div`
  max-width: ${({ theme }) => theme.breakpoints.desktop}px;
  margin: 0 auto;
`;

const CreatorLogoAndSocialsWrapper = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: row;
  width: 100%;

  margin-top: ${({ isMobile }) => (isMobile ? '-20px' : '-30px')};
  align-items: end;
  justify-content: space-between;
`;

const CreatorLogoWrapper = styled.div`
  margin-left: 16px;
  border-radius: 60px;
  width: 74px;
  height: 74px;
  box-shadow: 0px 2px 10px 0px rgba(20, 20, 20, 0.66);

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 120px;
    height: 120px;
  }
`;

const CreatorLogo = styled(Image)`
  border-radius: 60px;
`;

const CreatorSocialsWrapper = styled.div`
  display: flex;
  height: 48px;
  align-items: center;
  padding: 6px;
  margin-right: 16px;
  border-radius: 12px;
  background: rgba(20, 20, 20, 0.95);

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    height: 38px;
  }
`;

const SocialLinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const SocialLink = styled.a`
  width: 26px;
  height: 26px;
  border-radius: 22px;
  background: ${({ theme }) => theme.colors.neutral7};

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    width: 36px;
    height: 36px;
  }
`;

const CreatorTitleWrapper = styled.div`
  margin-top: 16px;
  margin-left: 16px;
  border-radius: 12px;
  border: 1px solid var(--opacity-15, rgba(20, 20, 20, 0.15));
  background: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 9)};
  width: fit-content;
  padding: 10px 12px;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    margin-top: 24px;
  }
`;

const CreatorTitle = styled(H4)`
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 22px;
  }
`;

const PreviewWarningWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
`;

type CustomCreatorCollectionHeaderProps = {
  creatorCollection: Shopify.Collection;
  isPreview?: boolean;
};

const CustomCreatorCollectionHeader: React.FC<CustomCreatorCollectionHeaderProps> = ({
  creatorCollection,
  isPreview,
}: CustomCreatorCollectionHeaderProps) => {
  const { state } = useStore();
  const size = useWindowSize();
  const theme = useContext(ThemeContext);

  const [isMobileViewport, setIsMobileViewport] = useState<boolean>(false);

  const {
    socialLinks,
    headerBackgroundURL,
    creatorLogoURL,
    previewSocialLinks,
    previewHeaderBackgroundURL,
    previewCreatorLogoURL,
  } = parseCollectionMetafields(creatorCollection?.metafields);

  const socialLinksObject = JSON.parse(isPreview ? previewSocialLinks || '{}' : socialLinks || '{}');

  useEffect(() => {
    if (!size.width) {
      return;
    }
    // eslint-disable-next-line react/destructuring-assignment
    const isMobileViewport = size.width < theme.breakpoints.desktop;
    setIsMobileViewport(isMobileViewport);
  }, [size]);

  const linksToIconsMap = {
    twitter: '/assets/icons/twitter-white-icon.svg',
    instagram: '/assets/icons/instagram-white-icon.svg',
    facebook: '/assets/icons/facebook-white-icon.svg',
    youtube: '/assets/icons/youtube-icon.svg',
    tiktok: '/assets/icons/tiktok-white-icon.svg',
    discord: '/assets/icons/discord-icon.svg',
    twitch: '/assets/icons/twitch-icon.svg',
    other: '/assets/icons/other-icon.svg',
  };

  const socialLinksButtons = Object.entries(socialLinksObject).map(([key, value]) => {
    const valueString = value as string;
    if (!valueString) return null;
    return (
      <SocialLinkWrapper>
        <SocialLink
          target="_blank"
          rel="noreferrer"
          key={key}
          href={valueString || ''}
          onClick={() =>
            posthog.capture(`custom_creator_page_social_click`, {
              social_platform: key,
              link_href: value,
              creator_page: creatorCollection?.title,
              user: state.user?.customer?.email,
            })
          }
        >
          <Image
            src={linksToIconsMap[key as keyof typeof linksToIconsMap]}
            height={isMobileViewport ? 26 : 36}
            width={isMobileViewport ? 26 : 36}
            alt={`${key} icon`}
          />
        </SocialLink>
      </SocialLinkWrapper>
    );
  });

  return (
    <CreatorHeaderWrapper>
      <CreatorHeaderBackgroundWrapper isMobile={isMobileViewport}>
        <CreatorHeaderBackground
          src={isPreview ? previewHeaderBackgroundURL : headerBackgroundURL}
          layout="fill"
          objectFit="cover"
          quality={90}
          alt="Creator header background"
          unoptimized
        />
        {isPreview && (
          <PreviewWarningWrapper>
            <PreviewWarning />
          </PreviewWarningWrapper>
        )}
      </CreatorHeaderBackgroundWrapper>
      <MaxDesktopWrapper>
        <CreatorLogoAndSocialsWrapper isMobile={isMobileViewport}>
          <CreatorLogoWrapper>
            <CreatorLogo
              src={isPreview ? previewCreatorLogoURL || '' : creatorLogoURL || ''}
              height={isMobileViewport ? 74 : 120}
              width={isMobileViewport ? 74 : 120}
              alt={`${creatorCollection?.title} logo`}
            />
          </CreatorLogoWrapper>
          {socialLinksButtons.length > 0 && (
            <CreatorSocialsWrapper>{socialLinksButtons.slice(0, 3)}</CreatorSocialsWrapper>
          )}
        </CreatorLogoAndSocialsWrapper>
        <CreatorTitleWrapper>
          <CreatorTitle>{creatorCollection?.title}</CreatorTitle>
        </CreatorTitleWrapper>
      </MaxDesktopWrapper>
    </CreatorHeaderWrapper>
  );
};

export default CustomCreatorCollectionHeader;
