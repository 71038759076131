import React, { useContext, useEffect, useState, useRef } from 'react';
import styled, { ThemeContext } from 'styled-components';
import Flickity, { FlickityOptions } from 'react-flickity-component';
import Link from 'next/link';
import { InView } from 'react-intersection-observer';
import posthog from 'posthog-js';
import { H2Styles, H6Styles, S2Styles, Disclaimer } from '../Typography';
import { useWindowSize } from '../../hooks/useWindowSize';
import { analyticsTrackListInView, isUserLoggedIn } from '../../utils/analytics';
import { useStore } from '../../store';
import { getProductIDFromShopifyGID, getModelsMeasurements, getProductType } from '../../utils/product';
import { getIsLifestyleImageryUpdateRequiredFromContext } from '../../context/product';
import { ProductType } from '../../types/common';

export type CarouselProps = {
  children: JSX.Element[];
  product?: Shopify.Product;
  title?: string;
  cardNumber?: number;
  link?: string;
  isInCollection?: boolean;
  isOnMainPage?: boolean;
  isComingSoon?: boolean;
  collectionForTracking?: Shopify.ProductEdge[];
  isPetition?: boolean;
  carouselLocation?: string;
};

type Card = {
  key: number;
  value: JSX.Element;
};

interface FlickityExtended extends Flickity {
  slider?: HTMLElement;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    margin-bottom: 0px;
  }
`;

const CarouselHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`;

const Title = styled.h2<{ isOnMainPage: boolean | undefined }>`
  ${H2Styles}
  ${({ isOnMainPage, theme }) =>
    isOnMainPage
      ? `font-weight: 900;
        font-size: 40px;
        line-height: 54px;
        color: ${theme.colors.primaryDark};`
      : `
      `}
  text-transform: uppercase;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    font-family: ${({ theme }) => theme.fonts.secondary}
    ${H6Styles}
    ${({ isOnMainPage, theme }) =>
      isOnMainPage
        ? `font-weight: 900;
        font-size: 20px;
        line-height: 38px;
        color: ${theme.colors.primaryDark};`
        : `
      `}
    margin-left:8px;
  }
`;

const ViewAllText = styled.a`
  ${S2Styles}
  display:block;
  color: ${({ theme }) => theme.colors.neutral7};
  cursor: pointer;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    margin-right: 8px;
  }
`;

const CarouselWrapper = styled.div``;

const CarouselItem = styled.div<{ cardNum: number }>`
  width: calc(100% / ${({ cardNum }) => cardNum});
  text-align: center;
`;

const DisclaimerWrapper = styled.div`
  display: flex;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.largeTablet}px) {
    position: relative;
    top: 0px;
  }
`;

const StyledFlickity = styled(Flickity)<{
  hasDisclaimer: boolean | undefined;
  isComingSoon: boolean | undefined;
  showArrow: boolean;
  isPetition: boolean | undefined;
  isLifestyleImageryUpdateRequired: boolean;
}>`
  .flickity-prev-next-button {
    display: ${({ showArrow }) => (showArrow ? 'block' : 'none')} !important;
  }

  ${({ hasDisclaimer, isComingSoon }) =>
    hasDisclaimer && !isComingSoon
      ? `.flickity-prev-next-button {
      top: 40%;
    }
    .flickity-page-dots {
      bottom: -8px;
    }`
      : `
  `}

  ${({ isPetition }) =>
    isPetition
      ? `.flickity-page-dots {
      bottom: -42px;
      }`
      : ``}

  ${({ isLifestyleImageryUpdateRequired }) =>
    isLifestyleImageryUpdateRequired
      ? `.flickity-page-dots {
    bottom: -60px;
    }`
      : ``}
`;

const Carousel: React.FC<CarouselProps> = ({
  children,
  product,
  title,
  cardNumber = 1,
  link,
  isInCollection = false,
  isOnMainPage,
  isComingSoon,
  collectionForTracking,
  isPetition,
  carouselLocation,
}: CarouselProps) => {
  const size = useWindowSize();
  const theme = useContext(ThemeContext);
  const isLifestyleImageryUpdateRequired = getIsLifestyleImageryUpdateRequiredFromContext();
  const flickityRef = useRef<FlickityExtended>();
  const { state } = useStore();

  const productType = getProductType(product?.productType || '');

  const [cards, setCards] = useState<Card[]>();
  const [displayedCardNum, setDisplayedCardNum] = useState<number | undefined>();
  const [showArrow, setShowArrow] = useState(false);
  const [showDots, setShowDots] = useState(false);
  const [draggable, setDraggable] = useState(true);

  const cardsArray = children.map((card, i) => ({ key: i, value: card }));

  const flickityOptions: FlickityOptions = {
    groupCells: true,
    initialIndex: 0,
    draggable: true,
    cellAlign: 'center',
  };

  useEffect(() => {
    if (size.width) {
      let currentCards: Card[] = cardsArray;
      let currentDisplayedCardNum: number;

      if (isInCollection) {
        currentDisplayedCardNum = size.width > theme.breakpoints.mobile ? 3 : 2;
        if (currentDisplayedCardNum * 4 < cardsArray.length) {
          currentCards = cardsArray.slice(0, currentDisplayedCardNum * 4);
        }
      } else {
        currentDisplayedCardNum = cardNumber;
      }

      setCards(currentCards);
      setDisplayedCardNum(currentDisplayedCardNum);
      setShowArrow(isInCollection ? size.width > theme.breakpoints.mobile && currentCards.length > 3 : true);
      setShowDots(currentCards.length > currentDisplayedCardNum);
      setDraggable(currentCards.length > 1);

      if (cardsArray.length === 1) {
        setShowArrow(false);
      }
    }
  }, [size.width, children]);

  const handleDragStart = () => {
    flickityRef.current?.slider?.childNodes.forEach((slide) => {
      const currentSlide = slide as HTMLDivElement;
      currentSlide.style.pointerEvents = 'none';
    });
  };

  const handleDragEnd = () => {
    flickityRef.current?.slider?.childNodes.forEach((slide) => {
      const currentSlide = slide as HTMLDivElement;
      currentSlide.style.pointerEvents = 'all';
    });
  };

  useEffect(() => {
    if (flickityRef.current) {
      flickityRef.current.on('dragStart', handleDragStart);
      flickityRef.current.on('dragEnd', handleDragEnd);
    }
    return () => {
      if (flickityRef.current) {
        flickityRef.current.off('dragStart', handleDragStart);
        flickityRef.current.off('dragEnd', handleDragEnd);
      }
    };
  }, [cards]);

  const handleOnChange = () => {
    if (!product) {
      // Not on PDP pages
      carouselLocation &&
        posthog.capture(`${carouselLocation}_carousel_swipe`, {
          selected_slide_index: flickityRef.current?.selectedIndex,
          event_location: 'image_carousel',
          total_slide_count: cards?.length,
          is_logged_in: isUserLoggedIn(state.user),
        });
    } else {
      posthog.capture('selected_image_changed', {
        selected_image_index: flickityRef.current?.selectedIndex,
        event_location: 'mobile_image_carousel',
        total_image_count: cards?.length,
        product_type: product?.productType,
        product_name: product?.title,
        shopify_product_id: product && getProductIDFromShopifyGID(product.id),
        is_logged_in: isUserLoggedIn(state.user),
      });
    }
  };

  useEffect(() => {
    if (flickityRef.current) {
      flickityRef.current.on('change', handleOnChange);
    }
    return () => {
      if (flickityRef.current) {
        flickityRef.current.off('change', handleOnChange);
      }
    };
  }, [cards]);

  return (
    <Container>
      <InView
        as="div"
        onChange={(inView, entry) => inView && analyticsTrackListInView(collectionForTracking, title, state.user)}
      >
        <CarouselHeader>
          {title && (
            <Title isOnMainPage={isOnMainPage} data-testid="title">
              {title}
            </Title>
          )}
          {link && (
            <Link href={link} passHref>
              <ViewAllText
                data-testid={`viewAll-${title?.split(' ').join('')}`}
                onClick={() => {
                  carouselLocation &&
                    posthog.capture(`${carouselLocation}_carouselViewAllLink_click`, {
                      link_href: link,
                      link_title: title,
                      is_logged_in: isUserLoggedIn(state.user),
                    });
                }}
              >
                View All
              </ViewAllText>
            </Link>
          )}
        </CarouselHeader>
        {displayedCardNum && cards && (
          <CarouselWrapper data-testid="carousel">
            <StyledFlickity
              isComingSoon={isComingSoon}
              isPetition={isPetition}
              isLifestyleImageryUpdateRequired={isLifestyleImageryUpdateRequired && productType === ProductType.Hoodie}
              hasDisclaimer={productType === ProductType.VinylFigure || false}
              className={isOnMainPage && title?.toLowerCase() === 'coming soon' ? 'invertColor' : ''}
              flickityRef={(ref) => {
                flickityRef.current = ref;
              }}
              elementType="div"
              options={{
                ...flickityOptions,
                prevNextButtons: showArrow,
                pageDots: showDots,
                draggable,
              }}
              disableImagesLoaded={false}
              showArrow={showArrow}
            >
              {cards.map((item) => (
                <CarouselItem
                  onClick={() =>
                    posthog.capture('product_thumbnail_click', {
                      product_title: item.value.props.product.title,
                      product_id: getProductIDFromShopifyGID(item.value.props.product.id),
                      location: `${title?.toLowerCase().replace(' ', '_')}_carousel`,
                      is_logged_in: isUserLoggedIn(state.user),
                    })
                  }
                  key={item.key}
                  cardNum={displayedCardNum}
                  data-testid="card"
                >
                  {item.value}
                </CarouselItem>
              ))}
            </StyledFlickity>
            {productType === ProductType.VinylFigure && !isComingSoon && (
              <DisclaimerWrapper>
                <Disclaimer data-testid="disclaimer">3D digital render shown</Disclaimer>
              </DisclaimerWrapper>
            )}
            {isPetition && (
              <DisclaimerWrapper>
                <Disclaimer>Reference images provided by creator. Final product results may vary.</Disclaimer>
              </DisclaimerWrapper>
            )}
            {product && isLifestyleImageryUpdateRequired && !!getModelsMeasurements(product.id) && (
              <DisclaimerWrapper>
                <Disclaimer>{getModelsMeasurements(product.id)}</Disclaimer>
              </DisclaimerWrapper>
            )}
          </CarouselWrapper>
        )}
      </InView>
    </Container>
  );
};

export default Carousel;
