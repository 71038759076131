import React from 'react';
import styled from 'styled-components';
import { utils } from '@makeship/core';
import { S2, Caption } from '../../../Typography';
import { formatNumberStringWithComma } from '../../../../utils/accounting';

type StretchGoalsProgressBarProps = {
  totalUnitsSold: number;
  milestones: number[];
};

const ProgressContainer = styled.div`
  position: relative;
  margin-right: 8px;
`;

const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
  border-radius: 8px;
  margin-top: 8px;
`;

const Progress = styled.div`
  height: 100%;
  background: linear-gradient(to right, #f06292, #fbc02d);
  position: relative;
  transition: width 0.3s ease;
  border-radius: 8px;
`;

// Hardcoded image path for now. Can be replaced with a prop later.
const RewardIcon = styled.div`
  position: absolute;
  top: -26px;
  right: -8px;
  width: 16px;
  height: 36px;
  background: url('/assets/stretch-goals/stretch-goals-pin-gt.png') no-repeat center center;
  background-size: contain;
`;

const InfoContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const UnitsRemaining = styled(S2)`
  font-size: 14px;
  margin-right: auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    font-size: 16px;
  }
`;

const TotalSold = styled(Caption)``;

const StretchGoalsProgressBar: React.FC<StretchGoalsProgressBarProps> = ({
  totalUnitsSold,
  milestones,
}: StretchGoalsProgressBarProps) => {
  const nextMilestone = milestones.find((milestone) => milestone > totalUnitsSold) || 0;
  const unitsUntilNextReward = Math.max(0, nextMilestone - totalUnitsSold);
  const progressPercentage = Math.min((totalUnitsSold / nextMilestone) * 100, 100);
  const milestoneCount = milestones.length;

  return (
    <ProgressContainer>
      <ProgressBar>
        <Progress
          style={{
            width: `${progressPercentage}%`,
          }}
        />
        {nextMilestone > 0 && <RewardIcon />}
      </ProgressBar>
      <InfoContainer>
        <UnitsRemaining>
          {unitsUntilNextReward === 0
            ? `${milestoneCount}/${milestoneCount} free gifts unlocked!`
            : `${formatNumberStringWithComma(unitsUntilNextReward)} units to your next free gift!`}
        </UnitsRemaining>
        <TotalSold>{formatNumberStringWithComma(totalUnitsSold)} sold</TotalSold>
      </InfoContainer>
    </ProgressContainer>
  );
};
export default StretchGoalsProgressBar;
