import React from 'react';
import styled from 'styled-components';
import { P2 } from '../Typography';

const SingleSizeOptionWrapper = styled.div<{
  isSelected: boolean;
  isCreatorBranded?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  width: 42px;
  height: 42px;

  border-radius: 8px;
  border: 1px solid
    ${({ theme, isSelected, isCreatorBranded }) =>
      isCreatorBranded ? theme.colors.neutral7 : isSelected ? theme.colors.primary : theme.colors.primaryDark};

  background-color: ${({ theme, isSelected, isCreatorBranded }) =>
    isCreatorBranded
      ? isSelected
        ? theme.colors.neutral7
        : theme.colors.neutral1
      : isSelected
      ? theme.colors.primary
      : theme.colors.neutral1};

  &:hover {
    ${({ theme, isSelected, isCreatorBranded }) =>
      !isSelected && `border: 2px solid ${isCreatorBranded ? theme.colors.neutral7 : theme.colors.primaryDark}`};
  }
`;

const OptionText = styled(P2)<{
  isSelected: boolean;
  isCreatorBranded?: boolean;
}>`
  color: ${({ theme, isSelected, isCreatorBranded }) =>
    isCreatorBranded
      ? isSelected
        ? theme.colors.neutral1
        : theme.colors.neutral7
      : isSelected
      ? theme.colors.neutral1
      : theme.colors.primaryDark};
  text-align: center;
  text-transform: capitalize;
`;

type SingleSizeOptionProps = {
  variant: Shopify.ProductVariant;
  selected: boolean;
  setSelectedVariant: (e: React.MouseEvent<HTMLDivElement>, variant: Shopify.ProductVariant) => void;
  isCreatorBranded?: boolean;
};

const SingleSizeOption: React.FC<SingleSizeOptionProps> = ({
  variant,
  selected,
  setSelectedVariant,
  isCreatorBranded,
}: SingleSizeOptionProps) => (
  <SingleSizeOptionWrapper
    isCreatorBranded={isCreatorBranded}
    isSelected={selected}
    onClick={(e) => setSelectedVariant(e, variant)}
  >
    <OptionText isCreatorBranded={isCreatorBranded} isSelected={selected}>
      {variant.title}
    </OptionText>
  </SingleSizeOptionWrapper>
);

export default SingleSizeOption;
