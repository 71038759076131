import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';
import { S3, Caption, Overline } from '../../../Typography';
import { AccordionArrow } from '../../../Accordion';
import { ReactComponent as Info } from '../../../../../public/assets/icons/info-icon-2.svg';

export type Goal = {
  amount: number;
  title: string;
  emoji: string;
  image: string;
  additionalText?: string;
};

type StretchGoalsRewardListProps = {
  title: string;
  goals: Goal[];
  totalUnitsSold: number;
};

const Container = styled.div`
  background: linear-gradient(90deg, #fa937e 0%, #e890bd 50%, #f6cf82 100%);
  border-radius: 8px;
  padding: 12px;
  margin-top: 16px;
`;

const RewardsHeader = styled.div<{ expanded: boolean }>`
  display: flex;
  align-items: center;
  margin-bottom: ${({ expanded }) => (expanded ? '16px' : '0')};
  transition: margin-bottom 0.3s ease;
  cursor: pointer;
  height: 38px;
`;

const GoalList = styled.ul<{ expanded: boolean }>`
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: ${({ expanded }) => (expanded ? '1000px' : '0')};
  overflow: hidden;
  transition: max-height 0.3s ease;
`;

const GoalItem = styled.li<{ unlocked: boolean }>`
  display: flex;
  align-items: start;
  margin-bottom: 12px;
`;

const GoalEmoji = styled.span`
  margin-right: 8px;
  padding-top: 2px;
  font-size: 18px;
`;

const GoalText = styled.div`
  display: flex;
  gap: 6px;
`;

const GoalTitle = styled(S3)`
  white-space: nowrap;
`;

const GoalImagesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const GoalImageWrapper = styled.div<{ unlocked: boolean }>`
  display: flex;
  position: relative;
  height: 92px;
  width: 92px;
  justify-content: center;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  border: 2px solid
    ${({ theme, unlocked }) =>
      unlocked ? theme.colors.neutral7 : utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  background: ${({ unlocked }) => (unlocked ? 'none' : 'rgba(0, 0, 0, 0.2)')};
  transition: background 0.3s ease, transform 0.3s ease;
  cursor: pointer;
`;

const GoalImage = styled(Image)<{ unlocked: boolean }>`
  filter: ${({ unlocked }) => (unlocked ? 'none' : 'brightness(0.7)')};
  opacity: 1;
  transition: filter 0.3s ease;
`;

const StatusIcon = styled.div<{ unlocked: boolean }>`
  position: absolute;
  top: 0;
  left: -2px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  z-index: 1;
`;

const InfoIconWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-right: auto;
  padding: 22px 0;
`;

const Tooltip = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.neutral6};
  right: -80px;
  bottom: 44px;
  width: 280px;
  padding: 8px 16px;
  border-radius: 12px 12px 0 12px;
  cursor: pointer;
  z-index: 2;
`;

const TooltipSubtitle = styled(S3)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const TooltipText = styled(Caption)`
  color: ${({ theme }) => theme.colors.neutral1};
  & > span {
    color: ${({ theme }) => theme.colors.secondary};
  }
`;

const InfoIcon = styled(Info)`
  margin-left: 8px;
  vertical-align: text-top;
`;

const Arrow = styled(AccordionArrow)`
  cursor: pointer;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const ModalContent = styled.div`
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  border-radius: 8px;
  background: white;
`;

const StyledOverline = styled(Overline)`
  margin-top: 8px;
  justify-self: center;
`;

const StretchGoalsRewardList: React.FC<StretchGoalsRewardListProps> = ({
  title,
  goals,
  totalUnitsSold,
}: StretchGoalsRewardListProps) => {
  const [expanded, setExpanded] = useState(false);
  const [isTooltipClicked, setIsTooltipClicked] = useState(false);
  const [enlargedImage, setEnlargedImage] = useState<string | null>(null);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleClick = (e: MouseEvent) => {
    if (!!ref.current && !ref.current.contains(e.target as Node)) {
      setIsTooltipClicked(false);
    }
  };

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const handleTooltipClick = () => {
    setIsTooltipClicked(!isTooltipClicked);
  };

  const handleImageClick = (image: string) => {
    setEnlargedImage(image);
  };

  const closeModal = () => {
    setEnlargedImage(null);
  };

  const tooltip = (
    <Tooltip>
      <TooltipSubtitle>How do stretch goals work?</TooltipSubtitle>
      <TooltipText>
        Each live product purchased will contribute to the goal. Once the goal is reached, every unit (regardless of
        when it was purchased) will include the reward. 1 of each reward per unit purchased.
      </TooltipText>
    </Tooltip>
  );

  return (
    <Container>
      <RewardsHeader expanded={expanded} onClick={toggleExpanded}>
        <S3>{title}</S3>
        <InfoIconWrapper
          ref={ref}
          onClick={(event) => {
            event.stopPropagation();
            handleTooltipClick();
          }}
        >
          <InfoIcon />
          {isTooltipClicked && tooltip}
        </InfoIconWrapper>
        <Arrow $visible={expanded} src="/assets/icons/dropdown-icon.svg" width={24} height={12} />
      </RewardsHeader>
      <GoalList expanded={expanded}>
        {goals.map((goal) => {
          const { amount, title, emoji, additionalText } = goal;
          const unlocked = totalUnitsSold >= amount;
          return (
            <GoalItem key={amount} unlocked={unlocked}>
              <GoalEmoji>{unlocked ? '✅' : '🔒'}</GoalEmoji>
              <GoalText>
                <GoalTitle>{amount.toLocaleString()} Sold:</GoalTitle>
                <Caption>
                  {title} {emoji}
                  {!!additionalText && additionalText}
                </Caption>
              </GoalText>
            </GoalItem>
          );
        })}
        <GoalImagesContainer>
          {goals.map((goal) => {
            const { amount, title, image } = goal;
            const unlocked = totalUnitsSold >= amount;
            return (
              <GoalImageWrapper unlocked={unlocked} onClick={() => handleImageClick(image)}>
                <GoalImage key={amount} src={image} width={74} height={74} alt={title} unlocked={unlocked} />
                <StatusIcon unlocked={unlocked}>{unlocked ? '✅' : '🔒'}</StatusIcon>
              </GoalImageWrapper>
            );
          })}
        </GoalImagesContainer>
        <StyledOverline>Tap images to view rewards!</StyledOverline>
      </GoalList>
      {enlargedImage && (
        <ModalOverlay onClick={closeModal}>
          <ModalContent
            onClick={(e) => {
              e.stopPropagation();
              closeModal();
            }}
          >
            <Image src={enlargedImage} alt="Enlarged Goal" layout="intrinsic" width={324} height={324} />
          </ModalContent>
        </ModalOverlay>
      )}
    </Container>
  );
};

export default StretchGoalsRewardList;
