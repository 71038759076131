import React from 'react';
import Image from 'next/image';
import styled from 'styled-components';
import { Element as ScrollElement } from 'react-scroll';
import { utils } from '@makeship/core';
import { Caption, P2, S2 } from '../../../Typography';
import { ProductType } from '../../../../types/common';
import { getFormattedAmount } from '../../../../utils/accounting';
import { variantDescription, variantIntro } from './data';
import { getProductType, parseMetafields } from '../../../../utils/product';
import { getProductFromContext } from '../../../../context/product';
import { VariantSelectionError } from '../../../../types/errors';

const VariantDescriptionText = styled(Caption)`
  color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
`;

const VariantContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ErrorMessage = styled(P2)`
  color: ${({ theme }) => theme.colors.error};
  margin-top: -16px;
  margin-bottom: 16px;
`;

const VariantIntroWrapper = styled.div`
  margin-bottom: 16px;
`;

const VariantsWrapper = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme, error }) => (error ? theme.colors.error : theme.colors.transparent)};
  margin: -2px 0 16px 0;
  border-radius: 8px;
`;

const Variant = styled.div<{ selected: boolean }>`
  display: flex;
  position: relative;
  align-items: center;
  cursor: pointer;
  margin: ${({ selected }) => (selected ? '-1px -1px 15px -1px' : '0 0 16px 0')};
  border: ${({ theme, selected }) =>
    selected ? `2px solid ${theme.colors.primary}` : `1px solid ${theme.colors.neutral3}`};
  border-radius: 8px;
  padding-right: 8px;
  &:last-child {
    margin-bottom: ${({ selected }) => (selected ? '-1px' : '0')};
  }
  & > ${P2} {
    margin-left: auto;
  }
  ${({ selected }) =>
    selected &&
    `&::after {
  content: url(/assets/icons/checkmark-icon-2.svg);
  position: absolute;
  top: -11px;
  right: 2px;
  width: 20px;
  height: 20px;
}`};
`;

const ImageWrapper = styled.div`
  position: relative;
  height: 100px;
  width: 100px;
  margin-right: 8px;
  border-radius: 8px 0 0 8px;
  overflow: hidden;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.mobile}px) {
    height: 80px;
    width: 80px;
  }
`;

const VariantContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  & > ${Caption} {
    color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.dark)};
  }
`;

type VariantSelectorProps = {
  errors: VariantSelectionError[] | undefined;
  selectedVariant?: Shopify.ProductVariant;
  setSelectedVariant: (variant: Shopify.ProductVariant) => void;
};

const VariantSelector: React.FC<VariantSelectorProps> = ({
  errors,
  selectedVariant,
  setSelectedVariant,
}: VariantSelectorProps) => {
  const product = getProductFromContext();
  const metafields = parseMetafields(product.metafields);
  const productType = getProductType(product.productType);

  const renderVariantDescription = (productType: ProductType, index: number): JSX.Element => {
    const description = variantDescription[productType];
    if (!description) return <></>;
    return <VariantDescriptionText>{description[index]}</VariantDescriptionText>;
  };

  const renderVariantIntro = (productType: ProductType, color?: string): JSX.Element => {
    const intro = variantIntro(color)[productType];
    if (!intro) return <></>;
    return (
      <VariantIntroWrapper>
        <S2>{intro.title}</S2>
        <P2>{intro.description}</P2>
      </VariantIntroWrapper>
    );
  };

  const renderErrorForVariant = () => {
    if (!errors) return;
    const error = errors.find((error) => error.productID === selectedVariant?.id);
    return error?.error;
  };

  return (
    <>
      {renderVariantIntro(productType, metafields.color)}
      <ScrollElement name="variants" />
      <VariantsWrapper error={(errors && !selectedVariant) || false}>
        {product.variants.edges.map(({ node }, index) => (
          <Variant key={node.id} onClick={() => setSelectedVariant(node)} selected={selectedVariant?.id === node.id}>
            <ImageWrapper>
              <Image
                src={node.image?.transformedSrc || '/assets/default_product_asset.png'}
                alt={node.image?.altText || 'variant image'}
                layout="fill"
                objectFit="contain"
                quality={90}
                unoptimized
              />
            </ImageWrapper>
            <VariantContentWrapper>
              <VariantContentRow>
                <S2>{node.title}</S2>
                <P2>${getFormattedAmount(node.price?.amount)}</P2>
              </VariantContentRow>
              <VariantContentRow>{renderVariantDescription(productType, index)}</VariantContentRow>
            </VariantContentWrapper>
          </Variant>
        ))}
      </VariantsWrapper>
      {errors && !selectedVariant && <ErrorMessage>{renderErrorForVariant()}</ErrorMessage>}
    </>
  );
};

export default VariantSelector;
