import React from 'react';
import styled from 'styled-components';
import Image from 'next/image';
import { utils } from '@makeship/core';
import { Overline, Caption, S3 } from '../../../Typography';

const CreatorMessageContainer = styled.div`
  display: flex;
  gap: 14px;
  margin: 36px auto;
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    padding: 20px 0 16px;
    margin: 0;
  }
`;

const CreatorMessageTextWrapper = styled.div`
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  border-radius: 0px 12px 12px 12px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 1px solid var(--opacity-15, rgba(20, 20, 20, 0.15));
  height: fit-content;
`;

const CreatorMessageText = styled(Caption)``;

const CreatorAvatarWrapper = styled.div`
  min-width: 48px;
  width: 48px;
  height: 48px;
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral1, 90)};
  border-radius: 8px;
  border: 1px solid var(--opacity-15, rgba(20, 20, 20, 0.15));
  @media screen and (min-width: ${({ theme }) => theme.breakpoints.desktop}px) {
    min-width: 64px;
    width: 64px;
    height: 64px;
  }
`;

const CreatorAvatar = styled(Image)`
  border-radius: 8px;
`;

const Pill = styled.div`
  background-color: ${({ theme }) => utils.hexToRGBA(theme.colors.neutral7, theme.alpha.medium)};
  border-radius: 46px;
  padding: 6px;
  width: fit-content;
  height: 21px;
  display: flex;
  align-items: center;
`;

const PillText = styled(Overline)`
  color: ${({ theme }) => theme.colors.neutral1};
`;

const CreatorTitleWrapper = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

type CreatorMessageProps = {
  collectionTitle: string;
  creatorMessage: string;
  creatorAvatarURL: string;
  creatorLogoURL: string;
};

const CreatorMessage: React.FC<CreatorMessageProps> = ({
  collectionTitle,
  creatorMessage,
  creatorAvatarURL,
  creatorLogoURL,
}: CreatorMessageProps) => (
  <CreatorMessageContainer>
    {(creatorAvatarURL || creatorLogoURL) && (
      <CreatorAvatarWrapper>
        <CreatorAvatar
          width={64}
          height={64}
          src={creatorAvatarURL || creatorLogoURL}
          objectFit="cover"
          alt="creator avatar"
        />
      </CreatorAvatarWrapper>
    )}
    <CreatorMessageTextWrapper>
      <CreatorTitleWrapper>
        <S3>{collectionTitle}</S3>
        <Pill>
          <PillText>Creator</PillText>
        </Pill>
      </CreatorTitleWrapper>
      <CreatorMessageText>{creatorMessage}</CreatorMessageText>
    </CreatorMessageTextWrapper>
  </CreatorMessageContainer>
);

export default CreatorMessage;
